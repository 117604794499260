import $ from 'jquery'
import * as project from './functions'
import * as animations from './animations'
import './polyfill'

window.onload = () => {
  project.lazyLoad()
  project.smoothScroll()
  project.menuOverlay()
  project.setupModalVideo()

  if ($('.slider-init').length > 0) {
    $('.slider-init').each(function() {
      project.setupSlideshow($(this), $(this).data('slick'), $(this).data('slides'))
    })
  }

  const bodyClasses = $('body').attr("class").split(/\s+/)
  
  $.each(bodyClasses, function(key, value) {
    switch(value) {
      case "home":
        animations.setupAnimations()
        project.heroRandomImages()
        break
      default:
        animations.setupLoader()
        
    }
  })
}
