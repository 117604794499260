import $ from 'jquery'
import LazyLoad from "vanilla-lazyload"
import { gsap, Power2, Linear } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

/* LIGHTBOX 2
  ----------------------------- */
export const setupAnimations = () => {
	// About Paragraphs
	const sectionParagraphs = gsap.utils.toArray('.section--about .section__paragraph')
    sectionParagraphs.forEach((sectionParagraph) => {
		gsap.from(sectionParagraph, {
			scrollTrigger: {
				trigger: sectionParagraph,
				start: "top 60%",
				toggleActions: "play none reverse reset",
			},
			alpha: 0,
			duration: 1.2,
			y: 64,
			ease: Power2.easeOut,
		})
	})

	// About Title
	gsap.from('.section--about .section__label-wrapper', {
		scrollTrigger: {
			trigger: '.section--about',
			start: "top 50%",
			toggleActions: "play none reverse reset",
		},
		alpha: 0,
		duration: 1.5,
		y: 64,
		ease: Power2.easeOut,
	})

	// About Image
	gsap.to('.section--about .section__image img', {
		scrollTrigger: {
			trigger: '.section--about .section__image',
			start: "top 60%",
			toggleActions: "play none reverse reset",
			scrub: 2
		},
		scale: 1.2,
		ease: Power2.easeOut,
	})

	// About Quote
	const tlQuote = gsap.timeline({
		scrollTrigger: {
			trigger: '.section__quote-wrapper',
			start: "top 60%",
			toggleActions: "play none reverse reset",
		}
	})

	tlQuote
		.from('.section__quote-wrapper .section__quote-icon', { alpha: 0, y: 32, duration: 0.4, ease: Power2.easeOut }, '+=0')
		.from('.section__quote-wrapper .section__quote-sentence', { alpha: 0, y: 32, duration: 0.4, ease: Power2.easeOut }, '-=0.2')
		.from('.section__quote-wrapper .section__quote-author', { alpha: 0, y: 32, duration: 0.4, ease: Power2.easeOut }, '-=0.2')
		.from('.section__quote-wrapper .section__quote-position', { alpha: 0, y: 32, duration: 0.4, ease: Power2.easeOut }, '-=0.2')

	// Companies
	let sectionCompanies = gsap.utils.shuffle(gsap.utils.toArray('.section--companies .section__company'))
	const tlCompanies = gsap.timeline({
		scrollTrigger: {
			trigger: '.section--companies',
			start: "top 60%",
			toggleActions: "play none none none",
		}
	})

	tlCompanies
		.from('.section--companies .section__header', { alpha: 0, y: 32, duration: 0.4, ease: Power2.easeOut }, '+=0')
		.from(sectionCompanies, { alpha: 0, stagger: 0.05, duration: 0.8, ease: Power2.easeOut }, '+=0')

	// Partners
	let sectionpartners = gsap.utils.shuffle(gsap.utils.toArray('.section--partners .section__partner'))
	const tlPartners = gsap.timeline({
		scrollTrigger: {
			trigger: '.section--partners',
			start: "top 60%",
			toggleActions: "play none none none",
		}
	})

	tlPartners
		.from('.section--partners .section__header', { alpha: 0, y: 32, duration: 0.4, ease: Power2.easeOut }, '+=0')
		.from(sectionpartners, { alpha: 0, stagger: 0.05, duration: 0.8, ease: Power2.easeOut }, '+=0')

	// Services
	const tlServices = gsap.timeline({
		scrollTrigger: {
			trigger: '.section--services',
			start: "top 60%",
			toggleActions: "play none none none",
		}
	})

	tlServices
		.from('.section--services .section__header', { alpha: 0, y: 32, duration: 0.4, ease: Power2.easeOut}, '+=0')
		.from('.section--services .section__item', { alpha: 0, stagger: 0.2, duration: 0.8, ease: Power2.easeOut }, '-=0.2')
		.from('.section--services .section__item-content', { alpha: 0, y: 64, stagger: 0.2, duration: 0.8, ease: Power2.easeOut }, '-=0.2')
}


/* SETUP LOADER
  ----------------------------- */
  export const setupLoader = () => {

	const tlLoader = gsap.timeline({})
	tlLoader
		.to('#loader .loader__bg', {width: '100%', height: '100%', duration: 1.2, ease: Power2.easeOut }, '+=0.4')
		.to('#loader .brand-logo', {alpha: 1, scale: 1, duration: 0.8, ease: Power2.easeOut }, '-=0.4')
		.to('#loader .loader--text', {marginTop: 0, alpha: 1, duration: 0.8, ease: Power2.easeOut }, '+=0')

	const tlLoaderBar = gsap.timeline({ delay: 2})
	tlLoaderBar
		.to('#loader .loader--bar-1', {x: 18, duration: 0.4, ease: Power2.easeOut }, '+=0.4')
		.to('#loader .loader--bar-2', {x: -18, duration: 0.4, ease: Power2.easeOut }, '-=0.4')
		.to('#loader .loader--bar-1', {x: 36, duration: 0.4, ease: Power2.easeOut }, '+=0.4')
		.to('#loader .loader--bar-3', {x: -18, duration: 0.4, ease: Power2.easeOut }, '-=0.4')
		.to('#loader .loader--bar-1', {x: 54, duration: 0.4, ease: Power2.easeOut }, '+=0.4')
		.to('#loader .loader--bar-4', {x: -18, duration: 0.4, ease: Power2.easeOut }, '-=0.4')
		.to('#loader .loader--bar-1, #loader .loader--bar-2, #loader .loader--bar-3, #loader .loader--bar-4', {x: 0, duration: 0.4, ease: Power2.easeOut }, '+=0.4')
		.to('#loader', {y: '-100vh', duration: 0.8, ease: Power2.easeOut }, '+=0.4')
		.to('body', {top: '0', duration: 0.8, ease: Power2.easeOut }, '-=0.4')


	//const tlLoaderEnd = gsap.timeline({delay: 2})


    //$('#loader').delay(1000).fadeOut(300)
  }
  