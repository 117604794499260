import $ from 'jquery'
import slick from 'slick-carousel'
import LazyLoad from "vanilla-lazyload"
import { gsap, Power2 } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)
import ModalVideo from 'modal-video'
import 'jquery-modal'

/* SETUP SLICK
  ----------------------------- */
export const setupSlideshow = (el, type = 'basic', slidesNumber = 1) => {
  const $el = $(el);
  const opts = {
    basic: {
      autoplay: true,
      dots: true,
      infinite: true,
    }
  }

  $el.slick(opts[type])
}

/* LAZY LOAD
  ----------------------------- */
export const lazyLoad = () => {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    data_src: "src",
  })
}


/* SMOOTH SCROLL
  ----------------------------- */
export const smoothScroll = () => {
  $('a[href*="#"]:not([href="#"]):not([href="#top"]):not([target="_blank"]):not([href*="#modal"])').click(function(e) {
    const target = $(this.hash)
    if (target.length > 1) e.preventDefault()
    $('html, body').animate({ scrollTop: target.offset().top-100 }, 1000)
  })

  $("a[href='#top']").click(function(e) {
    e.preventDefault()
    $('html, body').animate({ scrollTop: 0 }, 800)
  })
}

/* HEADER ANIMATION
  ----------------------------- */
export const menuOverlay = () => {
  const toggle = $('.header__toggle')
  const bar = $('.header__toggle .bar')
  const overlay = $('.header--offcanvas, .header__inner, .content-area, body')
  const link = $('.header--offcanvas a');
  const header = $('.header--main');

  toggle.on('click', function () {
    overlay.toggleClass('open')
    bar.toggleClass('animate')
    toggle.toggleClass('open')
  })

  link.on('click', function () {
    overlay.removeClass('open')
    bar.removeClass('animate')
    toggle.removeClass('open')
  })

  // window.onresize = () => {
  //   overlay.removeClass('open')
  //   bar.removeClass('animate')
  //   toggle.removeClass('open')
  // }

  $(document).scrollTop() > 200 ?
    header.addClass('sticky') :
    header.removeClass('sticky')

  $(window).scroll(function(e) {
    $(document).scrollTop() > 200 ?
      header.addClass('sticky') :
      header.removeClass('sticky')

    this.oldScroll > this.scrollY ?
      header.addClass('sticky-show').removeClass('sticky-hide') :
      header.addClass('sticky-hide').removeClass('sticky-show')
    this.oldScroll = this.scrollY
  })
}

/* SETUP MODALVIDEO
  ----------------------------- */
export const setupModalVideo = () => {
  if ($('.js-modal-btn').length > 0 || $('.js-modal-btn-vimeo').length > 0) {
    new ModalVideo('.js-modal-btn')
    new ModalVideo('.js-modal-btn-vimeo', { channel: 'vimeo' })
  }
}

/* HERO RANDOM IMAGES
  ----------------------------- */
export const heroRandomImages = () => {
  var urls = []

  $('#hidden-urls li').each(function () {
    urls.push($(this).text())
  })

  let sectionItems = gsap.utils.shuffle(gsap.utils.toArray('.section--hero .section__gallery .section__item'))
  gsap.from(sectionItems, {alpha: 0, scale: 0.9, stagger: 0.04, duration: 0.6, delay: 2, ease: Power2.easeOut, onComplete: setupInterval })


  function setupInterval() {
    setInterval(function () {
      var imagesNumber = window.innerWidth > 832 ? 9 : 4
      var selectedUrls = urls.sort(() => Math.random() - 0.5).slice(0, imagesNumber)
      var markup = selectedUrls.map(url => '<div class="section__item"><img class="cover-image" src="' + url + '" alt=""></div>').join('')

      sectionItems = gsap.utils.shuffle(gsap.utils.toArray('.section--hero .section__gallery .section__item'))
      let animation = gsap.to(sectionItems, {alpha: 0, scale: 0.9, stagger: 0.04, duration: 0.6, ease: Power2.easeOut, onComplete: changeItems })

      function changeItems() {
        $('.section--hero .section__gallery').html(markup)
        let sectionItems = gsap.utils.shuffle(gsap.utils.toArray('.section--hero .section__gallery .section__item'))
        animation = gsap.from(sectionItems, {alpha: 0, scale: 0.9, stagger: 0.04, duration: 0.6, ease: Power2.easeOut })
      }
    }, 5000)
  }

}